import { React, useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../src/App.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { eachDayOfInterval, format, getDay } from "date-fns";

import NavMenu from "../components/NavMenu";
import Graph from "../components/Graph";
import DigitalClock from "../components/DigitalClock";
import InfoCard from "../components/InfoCard";
import PerformanceCard from "../components/PerformanceCard";
// import Map from '../components/Map';
import CoursesStartingSoonTable from "../components/CoursesStartingSoonTable";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faTable,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const myData = [
  { date: "2025-03-27", name: "John" },
  { date: "2025-03-30", name: "Alice" },
  { date: "2025-03-30", name: "Harry" },
];

function DashboardHome() {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get("sessionData");
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data["time"]) {
        setIntervalValue(data["time"]);
      }
      if (data["isViewAllowed"] === 1) {
        navigateRef.current("/");
      }
    } else {
      navigateRef.current("/login");
    }
  }, []);

  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);

  const [interval, setIntervalValue] = useState([300000]); // to refresh and reload (initial 5 min)
  useEffect(() => {
    const intervalId = setInterval(async () => {
      callbacksRef.current();
    }, interval);
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [interval]);

  let currYear = new Date().getFullYear();
  let prevYear = currYear - 1;
  let nextYear = currYear + 1;

  let currDate = new Date(); // Get current date
  let nextYearFirstDay = new Date(currDate.getFullYear() + 1, 0, 1); // Jan 1st of next year
  let diffInMilliseconds = nextYearFirstDay - currDate;
  let remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function dateFromNumToMonth(num) {
    if (num === 1) return "Jan";
    if (num === 2) return "Feb";
    if (num === 3) return "Mar";
    if (num === 4) return "Apr";
    if (num === 5) return "May";
    if (num === 6) return "Jun";
    if (num === 7) return "Jul";
    if (num === 8) return "Aug";
    if (num === 9) return "Sep";
    if (num === 10) return "Oct";
    if (num === 11) return "Nov";
    if (num === 12) return "Dec";
  }

  function dateFromNumToMonthLong(num) {
    if (num === 1) return "January";
    if (num === 2) return "February";
    if (num === 3) return "March";
    if (num === 4) return "April";
    if (num === 5) return "May";
    if (num === 6) return "June";
    if (num === 7) return "July";
    if (num === 8) return "August";
    if (num === 9) return "September";
    if (num === 10) return "October";
    if (num === 11) return "November";
    if (num === 12) return "December";
  }

  const [stuEnrollCountCur, setStuEnrollCountCur] = useState(["--"]);
  const [stuApplyCountCur, setStuApplyCountCur] = useState(["--"]);
  const [stuEnrollCountPrev, setStuEnrollCountPrev] = useState(["--"]);
  const [stuApplyCountPrev, setStuApplyCountPrev] = useState(["--"]);
  const [recruitersCount, setRecruitersCount] = useState(["--"]);
  const [agentsCount, setAgentsCount] = useState(["--"]);

  const [enrollVsMonth, setEnrolVsMonth] = useState([]);
  const [applyVsMonth, setApplyVsMonth] = useState([]);
  const [enrollVsMonthPrev, setEnrolVsMonthPrev] = useState([]);
  const [applyVsMonthPrev, setApplyVsMonthPrev] = useState([]);

  const [topEnroll, setTopEnrol] = useState([]);
  const [topApplication, setTopApplication] = useState([]);
  const [enrollCourse, setEnrollCourse] = useState([]);

  const [studentAgeGroups, setStudentAgeGroups] = useState([]);
  const [studentCounts, setStudentCounts] = useState([]);
  const [studentCountries, setStudentCountries] = useState([]);
  const [studentGenders, setStudentGenders] = useState([]);
  const [
    EnrolmentLineAcrossMonthsLastYear,
    setEnrolmentLineAcrossMonthsLastYear,
  ] = useState([]);
  const [
    EnrolmentLineAcrossMonthsThisYear,
    setEnrolmentLineAcrossMonthsThisYear,
  ] = useState([]);
  const [
    ApplicantLineAcrossMonthsLastYear,
    setApplicantLineAcrossMonthsLastYear,
  ] = useState([]);
  const [
    ApplicantLineAcrossMonthsThisYear,
    setApplicantLineAcrossMonthsThisYear,
  ] = useState([]);

  const [AgentsLineAcrossMonthsLastYear, setAgentsLineAcrossMonthsLastYear] =
    useState([]);
  const [AgentsLineAcrossMonthsThisYear, setAgentsLineAcrossMonthsThisYear] =
    useState([]);

  // const [applyVsCountry, setApplyVsCountry] = useState([]);
  // const [enrollCountry, setEnrollCountry] = useState([]);
  // const [applyVsCountryPrev, setApplyVsCountryPrev] = useState([]);

  async function callbacks() {
    getEnrollData(prevYear, "", "");
    getEnrollData(currYear, "", "");
    getApplyData(prevYear, "", "");
    getApplyData(currYear, "", "");
    getAgentsData(currYear, "13");
    getTopEnrollment();
    getTopApplication();
    getEnrollCourse(currYear, "");
    getEnrolledStudentsByAge();
    getEnrolledStudentsByGender();
    getEnrolledStudentsByCountries();
    getEnrolmentBySBUCodeData();
    getApplicantStatusData();
    getEnrolmentTrendAcrossMonthsData();
    getCoursesStartingSoonData();
    getEnrolledStudentDemographicsByCountryData();
    getEnrolmentToApplicantRatioYearlyData();
    getTotalSaleBySBUCodeData();
    getApplicantTrendAcrossMonthsData();
    getAgentsTrendAcrossMonthsData();
    // getAverageDaysforEnrolmentData();
    // getEnrollCountryData(prevYear,"","");
  }

  // getEnrolmentToApplicantRatioYearly
  const [EnrolmentToApplicantRatioYearly, setEnrolmentToApplicantRatioYearly] =
    useState([]);
  const [
    EnrolmentToApplicantRatioPrevYear,
    setEnrolmentToApplicantRatioPrevYear,
  ] = useState([]);
  async function getEnrolmentToApplicantRatioYearlyData() {
    const url =
      process.env.REACT_APP_API + "/getEnrolmentToApplicantRatioYearly";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;

      if (data.length === 0) {
        setEnrolmentToApplicantRatioYearly(null);
        setEnrolmentToApplicantRatioPrevYear(null);
      } else {
        setEnrolmentToApplicantRatioYearly({
          ...data[0], // Ensuring all fields from API response are included
          total_students: data[0].total_students || 0, // Ensure default value if missing
          other_students: data[0].other_students || 0,
        });

        setEnrolmentToApplicantRatioPrevYear(data[1] || null);
      }
    } catch (error) {
      if (
        error.response &&
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  // Log state updates properly
  useEffect(() => {
    console.log("Updated Enrolment Data:", EnrolmentToApplicantRatioYearly);
  }, [EnrolmentToApplicantRatioYearly]);

  useEffect(() => {
    getEnrolmentToApplicantRatioYearlyData();
  }, []);

  // getEnrolledStudentDemographicsByCountry
  const [
    EnrolledStudentDemographicsByCountry,
    setEnrolledStudentDemographicsByCountry,
  ] = useState([]);
  async function getEnrolledStudentDemographicsByCountryData() {
    const url =
      process.env.REACT_APP_API + "/getEnrolledStudentDemographicsByCountry";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolledStudentDemographicsByCountryData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleEnrolledStudentDemographicsByCountryData(data) {
    const countryCounts = data.reduce(
      (acc, student) => {
        if (student.country_name === "Singapore") {
          acc.singapore += 1;
        } else if (student.country_name === "China") {
          acc.china += 1;
        } else {
          acc.others += 1;
        }
        return acc;
      },
      { singapore: 0, china: 0, others: 0 }
    );

    const tempData = [
      {
        label: "Singapore",
        y: countryCounts.singapore,
      },
      {
        label: "China",
        y: countryCounts.china,
      },
      {
        label: "Others",
        y: countryCounts.others,
      },
    ];

    setEnrolledStudentDemographicsByCountry(tempData);
  }

  // get CoursesStartingSoon
  const [CoursesStartingSoon, setCoursesStartingSoon] = useState([]);
  async function getCoursesStartingSoonData() {
    const url = process.env.REACT_APP_API + "/getCoursesStartingSoon";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCoursesStartingSoon(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolmentTrendAcrossMonthsData() {
    const url = process.env.REACT_APP_API + "/getEnrolmentTrendAcrossMonths";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolmentTrendAcrossMonthsData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleEnrolmentTrendAcrossMonthsData(data) {
    const prevYearData = data.filter(
      (enrolment) => enrolment.enrolled_year === prevYear
    );
    const currYearData = data.filter(
      (enrolment) => enrolment.enrolled_year === currYear
    );
    let count2 = 0;
    let count4 = 0;

    let cumulativeCount1 = 0;
    const lineData1 = prevYearData.map((enrolment) => {
      cumulativeCount1 += enrolment.enrolment_count;
      return {
        y: cumulativeCount1,
        original: enrolment.enrolment_count,
        label: dateFromNumToMonth((count4 += 1)),
      };
    });

    let cumulativeCount2 = 0;
    const lineData2 = currYearData.map((enrolment) => {
      cumulativeCount2 += enrolment.enrolment_count;
      return {
        y: cumulativeCount2,
        original: enrolment.enrolment_count,
        label: dateFromNumToMonth((count2 += 1)),
      };
    });

    setEnrolmentLineAcrossMonthsLastYear(lineData1);
    setEnrolmentLineAcrossMonthsThisYear(lineData2);
  }

  async function getApplicantTrendAcrossMonthsData() {
    const url = process.env.REACT_APP_API + "/getApplicantTrendAcrossMonths";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleApplicantTrendAcrossMonthsData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleApplicantTrendAcrossMonthsData(data) {
    const prevYearData = data.filter(
      (applicant) => applicant.application_year === prevYear
    );
    const currYearData = data.filter(
      (applicant) => applicant.application_year === currYear
    );
    let count2 = 0;
    let count4 = 0;

    let cumulativeCount1 = 0;
    const lineData1 = prevYearData.map((applicant) => {
      cumulativeCount1 += applicant.application_count;
      return {
        y: cumulativeCount1,
        original: applicant.application_count,
        label: dateFromNumToMonth((count4 += 1)),
      };
    });

    let cumulativeCount2 = 0;
    const lineData2 = currYearData.map((applicant) => {
      cumulativeCount2 += applicant.application_count;
      return {
        y: cumulativeCount2,
        original: applicant.application_count,
        label: dateFromNumToMonth((count2 += 1)),
      };
    });

    setApplicantLineAcrossMonthsLastYear(lineData1);
    setApplicantLineAcrossMonthsThisYear(lineData2);
  }

  async function getAgentsTrendAcrossMonthsData() {
    const url = process.env.REACT_APP_API + "/getAgentsTrendAcrossMonths";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleAgentsTrendAcrossMonthsData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleAgentsTrendAcrossMonthsData(data) {
    const prevYearData = data.filter((agent) => agent.agents_year === prevYear);
    const currYearData = data.filter((agent) => agent.agents_year === currYear);
    let count2 = 0;
    let count4 = 0;

    let cumulativeCount1 = 0;
    const lineData1 = prevYearData.map((agent) => {
      cumulativeCount1 += agent.agents_count;
      return {
        y: cumulativeCount1,
        original: agent.agents_count,
        label: dateFromNumToMonth((count4 += 1)),
      };
    });

    let cumulativeCount2 = 0;
    const lineData2 = currYearData.map((agent) => {
      cumulativeCount2 += agent.agents_count;
      return {
        y: cumulativeCount2,
        original: agent.agents_count,
        label: dateFromNumToMonth((count2 += 1)),
      };
    });

    setAgentsLineAcrossMonthsLastYear(lineData1);
    setAgentsLineAcrossMonthsThisYear(lineData2);
  }

  // getApplicantStatus
  const [ApplicantStatus, setApplicantStatus] = useState([]);
  async function getApplicantStatusData() {
    const url = process.env.REACT_APP_API + "/getApplicantStatus";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleApplicantStatusData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleApplicantStatusData(data) {
    const transformedData = data.map((status) => ({
      y: status["Num of Applicants"],
      label: status["Status"],
    }));
    setApplicantStatus(transformedData);
  }

  // get EnrolmentBySBUCode
  const [enrolmentBySBUCode, setEnrolmentBySBUCode] = useState([]);
  async function getEnrolmentBySBUCodeData() {
    const url = process.env.REACT_APP_API + "/getEnrolmentBySBUCode";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolmentBySBUCodeData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  function handleEnrolmentBySBUCodeData(data) {
    var arr = [];
    data.forEach((x) => {
      arr.push({ y: x.sbuCtr, label: x.sbu_description });
    });
    setEnrolmentBySBUCode(arr);
  }

  async function getTopEnrollment() {
    const url = process.env.REACT_APP_API + `/getNewEnroled`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {},
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data) {
        setTopEnrol(data);
      }
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getTopApplication() {
    const url = process.env.REACT_APP_API + `/getNewApplication`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {},
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data) {
        setTopApplication(data);
      }
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollData(
    selectedYear,
    selectedRegion,
    selectedCourseType
  ) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {
        year: selectedYear,
        type: "enrollment",
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType,
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolVsMonth(data, selectedYear);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getApplyData(
    selectedYear,
    selectedRegion,
    selectedCourseType
  ) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {
        year: selectedYear,
        type: "application",
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType,
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleApplyVsMonth(data, selectedYear);
      // handleApplyVsCountry(data, selectedYear);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getAgentsData(selectedYear, status) {
    const url = process.env.REACT_APP_API + `/getAgentsByStatus`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {
        year: selectedYear,
        status: status,
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data) {
        setAgentsCount(data.length);
      }
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollCourse(selectedYear, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getCourseEnrollmentList`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {
        year: selectedYear,
        courseTypeId: selectedCourseType,
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleCourseEnroll(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByAge() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudents`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {},
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      // handleStudentGenders(data);
      handleStudentAgeGroups(data);
      // handleStudentCountries(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByGender() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByGender`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {},
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentGenders(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByCountries() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByCountries`;
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {},
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentCountries(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status == 401 &&
        error.response.statusText == "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  const [topRecruiters, setTopRecruiters] = useState([]);
  async function getTopRecruitersData() {
    const url = process.env.REACT_APP_API + "/getTopRecruiter";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      setTopRecruiters(response.data.key);
    } catch (error) {
      if (
        !error.response?.data?.success &&
        error.response?.status === 401 &&
        error.response?.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getTopRecruitersData();
  }, []);

  const [weekTopRecruiter, setWeekTopRecruiter] = useState([]);
  async function getWeekTopRecruiterData() {
    const url = process.env.REACT_APP_API + "/getWeekTopRecruiter";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      setWeekTopRecruiter(response.data.key.slice(0, 1));
    } catch (error) {
      if (
        !error.response?.data?.success &&
        error.response?.status === 401 &&
        error.response?.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getWeekTopRecruiterData();
  }, []);

  const [monthTopRecruiter, setMonthTopRecruiter] = useState([]);
  const [selectedTopMonth, setSelectedTopMonth] = useState(
    new Date().getMonth() + 1
  );

  const getMonthTopRecruiterData = async (month) => {
    try {
      const url = `${process.env.REACT_APP_API}/getMonthTopRecruiter?month=${month}`;
      const configs = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(url, configs);
      if (response.data.success) {
        setMonthTopRecruiter(response.data.key.slice(0, 1));
      }
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response?.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  };

  const [totalSales, setTotalSales] = useState(0);
  const [highestRevenue, setHighestRevenue] = useState({
    amount: 0,
    sbu: "N/A",
  });
  const [lowestRevenue, setLowestRevenue] = useState({ amount: 0, sbu: "N/A" });
  const [highStudents, setHighStudents] = useState({ count: 0 });
  const [lowStudents, setLowStudents] = useState({ count: 0 });
  const [totalStudents, setTotalStudents] = useState({ count: 0 });

  async function getTotalSalesData() {
    const url = process.env.REACT_APP_API + "/getTotalSales";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);
      const data = response.data.key?.[0];

      if (data) {
        setTotalSales(data.TotalRevenue ?? 0);
        setHighestRevenue({
          amount: data.HighestRevenue ?? 0,
          sbu: data.HighestSBU ?? "N/A",
        });
        setLowestRevenue({
          amount: data.LowestRevenue ?? 0,
          sbu: data.LowestSBU ?? "N/A",
        });
        setHighStudents({
          count: data.HightStudents ?? 0, // Fixed key name
        });
        setLowStudents({
          count: data.LowStudents ?? 0, // Ensured fallback value
        });
        setTotalStudents({
          count: data.TotalStudents ?? 0, // Ensured fallback value
        });
      }
    } catch (error) {
      console.error(
        "Error fetching revenue data:",
        error.response?.data || error
      );

      if (error.response?.status === 401) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  }

  useEffect(() => {
    getTotalSalesData();
  }, []);

  const [saleSBU, setSaleSBU] = useState([]);
  const [selectedSaleMonth, setSelectedSaleMonth] = useState(
    new Date().getMonth() + 1
  );

  useEffect(() => {
    async function fetchSaleSBU() {
      const url = `${process.env.REACT_APP_API}/getSalebySBU?month=${selectedSaleMonth}`;
      const configs = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await axios.get(url, configs);
        if (response.data && Array.isArray(response.data.data)) {
          setSaleSBU(response.data.data);
        } else {
          console.error("Unexpected API response structure:", response.data);
          setSaleSBU([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setSaleSBU([]);
      }
    }

    fetchSaleSBU();
  }, [selectedSaleMonth]);

  const handleSaleMonthChange = (event) => {
    setSelectedSaleMonth(Number(event.target.value));
  };

  const [saleBySBUCode, setSaleBySBUCode] = useState([]);
  async function getTotalSaleBySBUCodeData() {
    const url = process.env.REACT_APP_API + "/getTotalSaleBySBUCode";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleTotalSaleBySBUCodeData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  function handleTotalSaleBySBUCodeData(data) {
    var arr = [];
    data.forEach((x) => {
      arr.push({ y: x.Total, label: x.sbu_description });
    });
    setSaleBySBUCode(arr);
  }

  const [averageTime, setAverageTime] = useState([]);
  async function getAverageDaysforEnrolmentData() {
    const url = process.env.REACT_APP_API + "/getAverageDaysforEnrolment";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);

      if (response.data.success && Array.isArray(response.data.data)) {
        handleAverageDaysEnroll(response.data.data);
      } else {
        console.error("Invalid API response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }

      if (error.response?.status === 401) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  }

  function handleAverageDaysEnroll(data) {
    if (!Array.isArray(data)) {
      console.error("Invalid data format:", data);
      return;
    }
    const tempData = data.map((course) => ({
      label: course.sbu_description,
      y: course.AvgTime,
    }));
    setAverageTime(tempData);
  }

  useEffect(() => {
    getAverageDaysforEnrolmentData();
  }, []); // Fetch data when the component mounts

  const [regionEnrolData, setRegionEnrolData] = useState([]);
  async function getRegionEnrolmentData() {
    const url = process.env.REACT_APP_API + "/getRegionEnrolApplication";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);
      setRegionEnrolData(response.data.key);
    } catch (error) {
      if (
        !error.response?.data?.success &&
        error.response?.status === 401 &&
        error.response?.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getRegionEnrolmentData();
  }, []);

  useEffect(() => {
    if (selectedTopMonth) {
      getMonthTopRecruiterData(selectedTopMonth);
    }
  }, [selectedTopMonth]);

  const handleTopMonthChange = (e) => {
    setSelectedTopMonth(Number(e.target.value));
  };

  /* This is for leave data*/
  const [leaveData, setLeaveData] = useState([]);

  useEffect(() => {
    fetchLeaveData();
  }, []);

  async function fetchLeaveData() {
    const url = process.env.REACT_APP_API + "/getRecruiterLeave";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);
      const result = response.data.key;

      const expanded = [];

      result.forEach((item) => {
        const from = new Date(item.from_date);
        const to = new Date(item.to_date);

        const dateRange = eachDayOfInterval({ start: from, end: to });

        dateRange.forEach((date) => {
          const day = getDay(date); // 0 = Sunday, 6 = Saturday
          if (day !== 0 && day !== 6) {
            expanded.push({
              date: format(date, "yyyy-MM-dd"),
              name: item.display_name,
              number_day: item.number_day,
            });
          }
        });
      });

      setLeaveData(expanded);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  }
  // async function getEnrollCountryData(selectedYear, selectedRegion, selectedCourseType) {
  //   const url = process.env.REACT_APP_API + `/getNewPerformance`;
  //   const configs = {
  //     headers:{
  //       'Authorization': 'Bearer '+localStorage.getItem("token"),
  //       'Content-Type': 'application/json',
  //     },
  //     params: {
  //       year: selectedYear,
  //       type: 'enrollment',
  //       recTeam: selectedRegion,
  //       courseTypeId: selectedCourseType
  //     }
  //   };
  //   try {
  //     const response = await axios.get(url, configs);
  //     const data = response.data.key;
  //     // handleApplyVsMonth(data, selectedYear);
  //     // handleApplyVsCountry(data, selectedYear, configs.params.type);
  //   } catch (error) {
  //     if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
  //       Cookies.remove('sessionData');
  //       localStorage.removeItem('token')
  //       navigate('/login')
  //       return;
  //     }
  //     console.error("Error fetching data:", error);
  //   }
  // }

  function handleEnrolVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data) {
        const monthCount = data.reduce(
          (total, element) => total + element[month.toLowerCase() + "_count"],
          0
        );
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      } else {
        return null;
      }
    }, []);
    if (year === currYear && data) {
      setRecruitersCount(data.length);
      setEnrolVsMonth(tempData);
      setStuEnrollCountCur(stuCount);
    } else if (year === prevYear && data) {
      setEnrolVsMonthPrev(tempData);
      setStuEnrollCountPrev(stuCount);
    }
  }

  function handleApplyVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data) {
        const monthCount = data.reduce(
          (total, element) => total + element[month.toLowerCase() + "_count"],
          0
        );
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      } else {
        return null;
      }
    }, []);
    if (year === currYear && data) {
      setApplyVsMonth(tempData);
      setStuApplyCountCur(stuCount);
    } else if (year === prevYear && data) {
      setApplyVsMonthPrev(tempData);
      setStuApplyCountPrev(stuCount);
    }
  }

  function handleCourseEnroll(data) {
    const tempData = data.map((course) => ({
      label: course.course_code,
      y: course.total,
    }));
    setEnrollCourse(tempData);
  }

  function handleStudentGenders(data) {
    const genderCounts = data.reduce(
      (acc, student) => {
        if (student.gender === "Male") {
          acc.male += 1;
        } else if (student.gender === "Female") {
          acc.female += 1;
        }
        return acc;
      },
      { male: 0, female: 0 }
    );

    const tempData = [
      {
        label: "Male",
        y: genderCounts.male,
      },
      {
        label: "Female",
        y: genderCounts.female,
      },
    ];

    setStudentGenders(tempData);
  }

  function handleStudentAgeGroups(data) {
    const ageGroupCounts = data.reduce(
      (acc, student) => {
        if (student.age >= 5 && student.age <= 19) {
          acc.ageGroup1 += 1;
        } else if (student.age > 19 && student.age <= 25) {
          acc.ageGroup2 += 1;
        } else if (student.age > 25 && student.age <= 30) {
          acc.ageGroup3 += 1;
        } else if (student.age > 30) {
          acc.ageGroup4 += 1;
        }
        return acc;
      },
      { ageGroup1: 0, ageGroup2: 0, ageGroup3: 0, ageGroup4: 0 }
    );

    const tempData = [
      {
        label: "< 20",
        y: ageGroupCounts.ageGroup1,
      },
      {
        label: "20-25",
        y: ageGroupCounts.ageGroup2,
      },
      {
        label: "26-30",
        y: ageGroupCounts.ageGroup3,
      },
      {
        label: "30+",
        y: ageGroupCounts.ageGroup4,
      },
    ];

    setStudentAgeGroups(tempData);
  }

  function handleStudentCountries(data) {
    const countryStudentCount = data.reduce((acc, student) => {
      const { country_name } = student;
      const country = acc.find((c) => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          student_count: 1,
          coordinates: [student.longitude, student.latitude],
        });
      } else {
        country.student_count += 1;
      }
      return acc;
    }, []);
    setStudentCounts(countryStudentCount);

    const countries = data.reduce((acc, studentCountry) => {
      const { country_name } = studentCountry;
      const country = acc.find((c) => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          coordinates: [studentCountry.longitude, studentCountry.latitude],
        });
      }
      return acc;
    }, []);
    setStudentCountries(countries);
  }

  // function handleApplyVsCountry(data, year, type) {
  //   let tempData = [];
  //   let chinaCount = 0;
  //   let singaporeCount = 0;
  //   let othersCount = 0;
  //   if(data){
  //     data.forEach((element, index) => {
  //       if (element['country_name'] === "China"){
  //         months.forEach((month, index1) => {
  //           chinaCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else if(element['country_name'] === "Singapore"){
  //         months.forEach((month, index1) => {
  //           singaporeCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else {
  //         months.forEach((month, index1) => {
  //           othersCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //     });
  //     tempData.push({ y: chinaCount, label: "China" });
  //     tempData.push({ y: singaporeCount, label: "Singapore" });
  //     tempData.push({ y: othersCount, label: "Others" });
  //     if(type === 'enrollment') {
  //       setEnrollCountry(tempData)
  //     } else {
  //       if (year === currYear){
  //         setApplyVsCountry(tempData);
  //       }
  //       else{
  //         setApplyVsCountryPrev(tempData);
  //       }
  //     }
  //   }
  // }

  const [showModal, setShowModal] = useState(false);

  const [isSec1Visible, setSec1Visible] = useState(true);
  const [isSec2Visible, setSec2Visible] = useState(false);
  const [isSec3Visible, setSec3Visible] = useState(false);
  const [isSec4Visible, setSec4Visible] = useState(false);
  const [isSec5Visible, setSec5Visible] = useState(false);
  const [isSec6Visible, setSec6Visible] = useState(false);
  const [isSec7Visible, setSec7Visible] = useState(false);
  const [isSec8Visible, setSec8Visible] = useState(false);
  const [slideVisible, setSlideVisible] = useState();
  const [slideExit, setSlideExit] = useState();

  function changeSection(direction = null) {
    if (direction == "l") {
      setSlideVisible("slide-visible-l");
      setSlideExit("slide-exit-l");

      if (isSec1Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(true);
      } else if (isSec2Visible) {
        setSec1Visible(true);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec3Visible) {
        setSec1Visible(false);
        setSec2Visible(true);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec4Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(true);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec5Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(true);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec6Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(true);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec7Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(true);
        setSec7Visible(false);
        setSec8Visible(false);
      } else {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(true);
        setSec8Visible(false);
      }
    } else {
      setSlideVisible("slide-visible-r");
      setSlideExit("slide-exit-r");

      if (isSec1Visible) {
        setSec1Visible(false);
        setSec2Visible(true);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec2Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(true);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec3Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(true);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec4Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(true);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec5Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(true);
        setSec7Visible(false);
        setSec8Visible(false);
      } else if (isSec6Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(true);
        setSec8Visible(false);
      } else if (isSec7Visible) {
        setSec1Visible(false);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(true);
      } else {
        setSec1Visible(true);
        setSec2Visible(false);
        setSec3Visible(false);
        setSec4Visible(false);
        setSec5Visible(false);
        setSec6Visible(false);
        setSec7Visible(false);
        setSec8Visible(false);
      }
    }
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        changeSection("r");
      }
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        changeSection("l");
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [changeSection]);

  const [selectedMonth, setSelectedMonth] = useState("February");

  const Topmonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 4;

  const nextItems = () => {
    if (startIndex + itemsPerPage < regionEnrolData.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const prevItems = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <div>
      <NavMenu />
      <Container fluid>
        <div className="d-flex flex-row">
          <div className="col-3" style={{ minHeight: "42vh" }}>
            <DigitalClock />
            <InfoCard
              stuEnrollCur={stuEnrollCountCur}
              stuApplyCur={stuApplyCountCur}
              stuEnrollPrev={stuEnrollCountPrev}
              stuApplyPrev={stuApplyCountPrev}
              agents={agentsCount}
              recruiters={recruitersCount}
              currYear={currYear}
              prevYear={prevYear}
              nextYear={nextYear}
              remainingDays={remainingDays}
            />
          </div>

          {/* <div className="col-1 d-flex align-items-center" style={{width: '3%'}}>
              <button className="btn btn-secondary" style={{transform: 'rotate(90deg)'}} onClick={changeSection('l')}> 
                <FontAwesomeIcon color={"white"} icon={faSortDown} style={{width:'2rem'}}/>
              </button>
            </div> */}

          {/* Section 1 */}
          {isSec1Visible && (
            <div
              className={`col ${isSec1Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "42rem" }}
            >
              <div style={{ height: "50%" }}>
                <Graph
                  data={enrollVsMonthPrev}
                  data1={enrollVsMonth}
                  title={"Student Enrolment " + prevYear + " vs " + currYear}
                  colText1={`${prevYear}`}
                  colText2={`${currYear}`}
                  graphType={"multiCol"}
                  height="95%"
                  width="95%"
                />
              </div>
              <div style={{ height: "50%" }}>
                <Graph
                  data={applyVsMonthPrev}
                  data1={applyVsMonth}
                  title={"Student Application " + prevYear + " vs " + currYear}
                  colText1={`${prevYear}`}
                  colText2={`${currYear}`}
                  graphType={"multiCol"}
                  height="95%"
                  width="95%"
                />
              </div>
            </div>
          )}
          {isSec1Visible && (
            <div
              className={`col ${isSec1Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "42rem" }}
            >
              <div style={{ height: "50%", fontFamily: "Roboto-Regular" }}>
                <PerformanceCard
                  enrollmentList={topEnroll}
                  title={`Latest 5 Enrolments`}
                  type="enrollment"
                  height="95%"
                  width="95%"
                />
              </div>
              <div style={{ height: "50%", fontFamily: "Roboto-Regular" }}>
                <PerformanceCard
                  enrollmentList={topApplication}
                  title={`Latest 5 Applications`}
                  type="application"
                  height="95%"
                  width="95%"
                />
              </div>
            </div>
          )}

          {/* Section 2 */}
          {isSec2Visible && (
            <div
              className={`col ${isSec2Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "42rem" }}
            >
              <div style={{ height: "100%" }}>
                <Graph
                  data={enrollCourse}
                  title={"Course Enrolment - " + currYear}
                  title1={"Student Count"}
                  title2={"Courses"}
                  graphType={"barChart"}
                  interval={2}
                  height="100%"
                  width="95%"
                />
              </div>
            </div>
          )}
          {isSec2Visible && (
            <div
              className={`col ${isSec2Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "40rem" }}
            >
              <div
                className="d-flex flex-row justify-content-between"
                style={{ height: "49%", width: "95%" }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#32373A",
                    color: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "15px",
                    borderRadius: "10px",
                    fontFamily: "Roboto-Regular",
                  }}
                >
                  <h5
                    style={{ marginBottom: "10px", textAlign: "center" }}
                    className="mb-4"
                  >
                    {EnrolmentToApplicantRatioYearly.year} Application to
                    Enrolment
                  </h5>

                  <div
                    style={{
                      fontSize: "30px",
                      backgroundColor: "#f8c94e",
                      color: "#3d3d3d",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      marginBottom: "15px",
                    }}
                  >
                    {EnrolmentToApplicantRatioYearly.applicant_to_enrolment_percentage.toFixed(
                      1
                    )}
                    %
                  </div>

                  <h6
                    style={{
                      fontWeight: "bold",
                      marginBottom: "15px",
                      fontSize: "20px",
                    }}
                    className="mb-4"
                  >
                    {EnrolmentToApplicantRatioYearly.year} Conversion Ratio
                  </h6>

                  <div className="d-flex justify-content-center circle-ratio">
                    {[
                      {
                        value: EnrolmentToApplicantRatioYearly.applicants,
                        label: "Total Number Application",
                        color: "#f8c94e",
                      },
                      {
                        value: EnrolmentToApplicantRatioYearly.total_students,
                        label: "Total Number Enrolment",
                        color: "#76d7c4",
                      },
                      {
                        value: EnrolmentToApplicantRatioYearly.students,
                        label: `${EnrolmentToApplicantRatioYearly.year} Actual Enrolment`,
                        color: "#76d7c4",
                      },
                      {
                        value: EnrolmentToApplicantRatioYearly.other_students,
                        label: "Last Year Applications' Enrolment",
                        color: "#76d7c4",
                      },
                    ].map((item, index) => (
                      <div key={index} className="circle-position">
                        <div
                          style={{
                            backgroundColor: item.color,
                          }}
                          className="circle"
                        >
                          {item.value}
                        </div>
                        <p className="circle-label">{item.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <Graph
                  data={studentGenders}
                  title={"Enrolment By Gender Groups - " + currYear}
                  graphType={"pie"}
                  height="95%"
                  width="48%"
                  radius="75"
                /> */}
                {/* <Graph
                  title={
                    "Yearly Comparison of Enrolled Students and Applicants"
                  }
                  title2="No. of Student/Applicants"
                  data={EnrolmentToApplicantRatioYearly}
                  data1={EnrolmentToApplicantRatioPrevYear}
                  label={currYear}
                  label2={currYear - 1}
                  graphType={"barChart2"}
                  height="95%"
                  width="48%"
                  radius="75"
                /> */}
                {/* <Graph data={EnrolmentToApplicantRatioYearly} data1={EnrolmentToApplicantRatioPrevYear} label={currYear+" Enrolled"} label1={currYear-1+" Applicants"} 
                    title={currYear+" Application to Enrolment Conversion Rate"} subtitle={(EnrolmentToApplicantRatioYearly).toFixed(1) + ' %'} graphType={"doughnut"} height="95%" width="48%" radius="75"/> */}
                {/* <Graph data={stuEnrollCountCur} data1={stuApplyCountCur-stuEnrollCountCur} label={"Enrolled"} label1={"Remaining"} 
                    title={"Application to Enrolment Conversion Rate - " + currYear} subtitle={((stuEnrollCountCur*100)/stuApplyCountCur).toFixed(1) + ' %'} graphType={"doughnut"} height="95%" width="48%" radius="75"/> */}
              </div>
              <br></br>
              <div
                className="d-flex flex-row justify-content-between"
                style={{ height: "50%", width: "95%" }}
              >
                <Graph
                  data={enrolmentBySBUCode}
                  title={"Student Population By SBU"}
                  graphType={"doughnut2"}
                  height="95%"
                  width="100%"
                />
                {/* <Graph
                  data={studentAgeGroups}
                  title={"Enrolment By Age Groups - " + currYear}
                  title1="Student Count"
                  title2="Age Groups"
                  graphType={"barChart"}
                  interval={5}
                  height="100%"
                  width="50%"
                /> */}
                {/* <Graph
                  data={
                    EnrolmentToApplicantRatioYearly.applicant_to_enrolment_percentage
                  }
                  data1={
                    EnrolmentToApplicantRatioPrevYear.applicant_to_enrolment_percentage
                  }
                  label={currYear + " Enrolled"}
                  label1={currYear - 1 + " Applicants"}
                  title={currYear + " Application to Enrolment Conversion Rate"}
                  subtitle={
                    EnrolmentToApplicantRatioYearly.applicant_to_enrolment_percentage.toFixed(
                      1
                    ) + " %"
                  }
                  graphType={"doughnut"}
                  width="50%"
                  radius="75"
                /> */}
              </div>
            </div>
          )}

          {/* Section 3 */}
          {isSec3Visible && (
            <div
              className={`col ${
                isSec3Visible ? slideVisible : slideExit
              } d-flex justify-content-center align-items-center`}
              style={{ minHeight: "42rem" }}
            >
              {/* <Map data1={studentCounts} data2={studentCountries}/> */}
              <div className={"container text-center h-100"}>
                <div className={"row row-cols-2 h-100"}>
                  <div className={"col"}>
                    <Graph
                      data={EnrolmentLineAcrossMonthsLastYear}
                      linedata1={EnrolmentLineAcrossMonthsLastYear}
                      linedata2={EnrolmentLineAcrossMonthsThisYear}
                      title={
                        "Cumulative Enrolment " + prevYear + " vs " + currYear
                      }
                      colText1={`${prevYear}`}
                      colText2={`${currYear}`}
                      lineText1={`${prevYear}`}
                      lineText2={`${currYear}`}
                      graphType={"combo"}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      data={ApplicantLineAcrossMonthsLastYear}
                      linedata1={ApplicantLineAcrossMonthsLastYear}
                      linedata2={ApplicantLineAcrossMonthsThisYear}
                      title={
                        "Cumulative Applications " +
                        prevYear +
                        " vs " +
                        currYear
                      }
                      colText1={`${prevYear}`}
                      colText2={`${currYear}`}
                      lineText1={`${prevYear}`}
                      lineText2={`${currYear}`}
                      graphType={"combo"}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      data={AgentsLineAcrossMonthsLastYear}
                      linedata1={AgentsLineAcrossMonthsLastYear}
                      linedata2={AgentsLineAcrossMonthsThisYear}
                      title={
                        "Cumulative Active Agents " +
                        prevYear +
                        " vs " +
                        currYear
                      }
                      colText1={`${prevYear}`}
                      colText2={`${currYear}`}
                      lineText1={`${prevYear}`}
                      lineText2={`${currYear}`}
                      graphType={"combo"}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      data={ApplicantStatus}
                      title={"Applicant Status for the last 12 months"}
                      graphType={"pie"}
                      height="95%"
                      width="100%"
                    />
                    {/* <Graph
                      data={EnrolledStudentDemographicsByCountry}
                      title={"Enrolled Students by Country"}
                      graphType="pie"
                      height="95%"
                      width="95%"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Section 5 */}
          {isSec5Visible && (
            <div
              className={`col ${
                isSec5Visible ? slideVisible : slideExit
              } d-flex justify-content-center align-items-center`}
              style={{ minHeight: "42rem", fontFamily: "Roboto-Regular" }}
            >
              <div
                className={"d-flex flex-column"}
                style={{
                  padding: "15px 20px",
                  borderRadius: "8px",
                  background: "rgba(40, 44, 52, 0.8)",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-itemss-center">
                  <span
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "22px",
                    }}
                  >
                    Courses Starting Soon
                  </span>
                  <i className="text-warning">
                    Course continues to be shown for 21 days after SOC.
                  </i>
                </div>
                <CoursesStartingSoonTable tableData={CoursesStartingSoon} />
              </div>
            </div>
          )}

          {/* Section 4 */}
          {isSec4Visible && (
            <div
              className={`col ${isSec4Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "30rem" }}
            >
              <div
                className="container-fluid"
                style={{
                  minHeight: "30rem",
                  padding: "1rem",
                  marginTop: "-18px",
                }}
              >
                {/* Top Section */}
                <div className="row mb-4">
                  <div className="col-12">
                    <div
                      className="card text-center p-3 w-100 dark text-white"
                      style={{
                        borderRadius: "10px",
                        fontFamily: "Roboto-Regular",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center position-relative">
                        <div className="flex-grow-1 text-center">
                          <h4 className="text-white mb-4 fw-bold">
                            Top 3 Recruiters for Year (2025)
                          </h4>
                        </div>
                        <span
                          className="d-flex align-items-center text-white"
                          style={{ cursor: "pointer", marginTop: "-30px" }}
                          onClick={() => setShowModal(true)}
                        >
                          Expand
                          <FontAwesomeIcon
                            icon={faTable}
                            className="ms-2 text-white"
                          />
                        </span>
                      </div>

                      <div className="d-flex justify-content-around mt-4">
                        {topRecruiters.slice(0, 3).map((recruiter, index) => (
                          <div
                            key={index}
                            className="card text-center p-3 text-white position-relative"
                            style={{
                              width: "30%",
                              borderRadius: "10px",
                              background: "#373B3E",
                            }}
                          >
                            <div
                              className="position-absolute bg-warning text-dark d-flex justify-content-center align-items-center"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                top: "-10px",
                                right: "-10px",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <div
                                className="rounded-circle d-flex justify-content-center align-items-center"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                  border: "1px white solid",
                                  backgroundColor:
                                    index === 0
                                      ? "#FFC107"
                                      : index === 1
                                      ? "#007BFF"
                                      : "#17A2B8", // Gold, Blue, Cyan
                                }}
                              >
                                {recruiter.recruiter_name
                                  .charAt(0)
                                  .toUpperCase()}
                              </div>
                              <div
                                className="d-flex flex-column text-start"
                                style={{ flex: 1 }}
                              >
                                <p className="mb-0">
                                  {recruiter.recruiter_name}
                                </p>
                                <p
                                  className="small mb-0 text-uppercase"
                                  style={{ fontSize: "13px", color: "#ecf0f1" }}
                                >
                                  {recruiter.region}
                                </p>
                              </div>
                              <strong
                                style={{
                                  fontSize: "30px",
                                  marginRight: "10px",
                                }}
                              >
                                {recruiter.Enrolment}
                              </strong>
                            </div>
                          </div>
                        ))}
                      </div>

                      <small
                        className="mt-3 text-start text-white"
                        style={{ marginLeft: "20px", fontSize: "12px" }}
                      >
                        * The ranking is based on the student enrolment followed
                        by applications and agents.
                      </small>
                    </div>
                  </div>
                  {showModal && (
                    <div
                      className="modal fade show"
                      style={{
                        display: "block",
                        background: "rgba(0,0,0,0.5)",
                        fontFamily: "Roboto-Regular",
                      }}
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title fw-bold">
                              Recruiters' Table
                            </h5>
                            <button
                              className="btn-close"
                              onClick={() => setShowModal(false)}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <table
                              className="table table-bordered"
                              style={{ fontSize: "16px" }}
                            >
                              <thead className="table-dark">
                                <tr>
                                  <th style={{ textAlign: "center" }}>#</th>
                                  <th style={{ textAlign: "center" }}>
                                    Recruiter Name
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Application (2025)
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Enrolment
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {topRecruiters.map((recruiter, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      position: "relative",
                                      backgroundColor: "#121212",
                                      color: "white",
                                    }}
                                    className={index < 3 ? "top-three" : ""}
                                  >
                                    <td style={{ width: "8%" }}>{index + 1}</td>
                                    <td>{recruiter.recruiter_name}</td>
                                    <td
                                      style={{
                                        width: "20%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {recruiter.Application}
                                    </td>
                                    <td
                                      style={{
                                        width: "20%",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {recruiter.Enrolment}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="modal-footer">
                            <button
                              className="btn btn-secondary"
                              onClick={() => setShowModal(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Bottom Section */}
                <div className="row">
                  <div className="col-6">
                    <div
                      className="card text-center p-3 bg-light dark h-100"
                      style={{ fontFamily: "Roboto-Regular" }}
                    >
                      <h5 className="text-center text-white fw-bold">
                        Top Recruiter for This Week
                      </h5>
                      {weekTopRecruiter.length > 0 ? (
                        <>
                          <div
                            className="rounded-circle bg-warning d-flex justify-content-center align-items-center mx-auto mb-2 mt-4"
                            style={{
                              width: "80px",
                              height: "80px",
                              border: "2px white solid",
                              fontSize: "20px",
                            }}
                          >
                            {weekTopRecruiter[0].recruiter_name
                              .charAt(0)
                              .toUpperCase()}
                            {/* {weekTopRecruiter[0].recruiter_name
                              .split(" ")
                              .map((name) => name.charAt(0))
                              .join("")}{" "} */}
                          </div>
                          <p
                            className="text-center text-white"
                            style={{ fontSize: "20px" }}
                          >
                            {weekTopRecruiter[0].recruiter_name}
                          </p>
                          <div className="d-flex justify-content-around mt-4 text-white">
                            <div className="text-center">
                              <strong style={{ fontSize: "24px" }}>
                                {weekTopRecruiter[0].Enrolment}
                              </strong>
                              <p>Student Enrolments</p>
                            </div>
                            <div
                              style={{
                                width: "2px",
                                height: "50px",
                                backgroundColor: "white",
                                margin: "0 20px",
                              }}
                            ></div>
                            <div className="text-center">
                              <strong style={{ fontSize: "24px" }}>
                                {weekTopRecruiter[0].Application}
                              </strong>
                              <p>Student Applications</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-white mt-4 center">
                          There is no student enrolment this week.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-6">
                    <div
                      className="card text-center p-3 bg-light dark"
                      style={{ fontFamily: "Roboto-Regular" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-white m-0 fw-bold">
                          Top Recruiter of{" "}
                          {dateFromNumToMonthLong(selectedTopMonth)}
                        </h5>

                        <div className="d-flex align-items-center">
                          <span className="text-white me-2">Select:</span>
                          <select
                            className="form-select w-auto text-white border-0 ms-2"
                            style={{ background: "#373B3E" }}
                            value={selectedTopMonth}
                            onChange={handleTopMonthChange}
                          >
                            {Array.from({ length: 12 }, (_, i) => i + 1).map(
                              (month) => (
                                <option key={month} value={month}>
                                  {new Date(0, month - 1).toLocaleString(
                                    "default",
                                    { month: "long" }
                                  )}
                                </option>
                              )
                            )}
                          </select>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="position-absolute"
                            style={{
                              right: "30px",
                              transform: "translateY(-20%)",
                              pointerEvents: "none",
                              color: "white",
                            }}
                          />
                        </div>
                      </div>
                      {monthTopRecruiter.length > 0 && (
                        <>
                          <div
                            className="rounded-circle bg-warning d-flex justify-content-center align-items-center mx-auto mb-2 mt-4"
                            style={{
                              width: "80px",
                              height: "80px",
                              border: "2px white solid",
                              fontSize: "20px",
                            }}
                          >
                            {monthTopRecruiter[0].recruiter_name
                              .charAt(0)
                              .toUpperCase()}
                            {/* {monthTopRecruiter[0].recruiter_name
                              .split(" ")
                              .map((name) => name.charAt(0))
                              .join("")} */}
                          </div>
                          <p
                            className="text-center text-white"
                            style={{ fontSize: "20px" }}
                          >
                            {monthTopRecruiter[0].recruiter_name}
                          </p>
                          <div className="d-flex justify-content-around mt-4 text-white">
                            <div className="text-center">
                              <strong style={{ fontSize: "24px" }}>
                                {monthTopRecruiter[0].Enrolment}
                              </strong>
                              <p>Student Enrolments</p>
                            </div>
                            <div
                              style={{
                                width: "2px",
                                height: "50px",
                                backgroundColor: "white",
                                margin: "0 20px",
                              }}
                            ></div>
                            <div className="text-center">
                              <strong style={{ fontSize: "24px" }}>
                                {monthTopRecruiter[0].Application}
                              </strong>
                              <p>Student Applications</p>
                            </div>
                            {/* <div className="text-center">
                              <strong style={{ fontSize: "24px" }}>
                                {monthTopRecruiter[0].TotalAgents}
                              </strong>
                              <p>Active Agents</p>
                            </div> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-3 position-relative">
                  {regionEnrolData.length > itemsPerPage && (
                    <button
                      className="btn btn-dark position-absolute start-0 top-50"
                      style={{ marginLeft: "-50px" }}
                      onClick={prevItems}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  <div className="row row-cols-1 row-cols-md-4 g-4">
                    {regionEnrolData
                      .slice(startIndex, startIndex + itemsPerPage)
                      .map((data, index) => (
                        <div key={index} className="col">
                          <div
                            className="card text-center dark"
                            style={{
                              color: "#E0E0E0",
                              borderRadius: "10px",
                              fontFamily: "Roboto-Regular",
                            }}
                          >
                            {/* Card Header */}
                            <div
                              className="card-header text-uppercase"
                              style={{
                                backgroundColor: "#373B3E",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                color: "white",
                                padding: "10px",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {data.region}
                            </div>

                            {/* Card Body */}
                            <div className="card-body p-4">
                              <div className="row text-center">
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    Enrolment
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "28px",
                                      fontWeight: "bold",
                                      color: "#D1D1D1",
                                    }}
                                  >
                                    {data.Total_Enrolment}
                                  </p>
                                </div>
                                <div className="col"></div>
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    Appplication
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "28px",
                                      fontWeight: "bold",
                                      color: "#D1D1D1",
                                    }}
                                  >
                                    {data.Total_Application}
                                  </p>
                                </div>
                              </div>
                              <div className="row text-center mb-3">
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "2px",
                                      backgroundColor: "#E0E0E0",
                                      borderRadius: "8px",
                                      color: "#424242",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.PreviousMonth}
                                  </p>
                                </div>
                                <div className="col"></div>
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "2px",
                                      backgroundColor: "#E0E0E0",
                                      borderRadius: "8px",
                                      color: "#424242",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.ThisMonth}
                                  </p>
                                </div>
                              </div>
                              <div className="row text-center">
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      color:
                                        data.LastMonth_Enrolment >
                                        data.Current_Enrolment
                                          ? "#FF9800"
                                          : "#D1D1D1",
                                    }}
                                  >
                                    {data.LastMonth_Enrolment}
                                  </p>
                                </div>
                                <div className="col mt-2">
                                  <p style={{ fontSize: "15px" }}>Enrolment</p>
                                </div>
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      color:
                                        data.Current_Enrolment >
                                        data.LastMonth_Enrolment
                                          ? "#FF9800"
                                          : "#D1D1D1",
                                    }}
                                  >
                                    {data.Current_Enrolment}
                                  </p>
                                </div>
                              </div>
                              <div className="row text-center">
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      color:
                                        data.LastMonth_Application >
                                        data.Current_Application
                                          ? "#FF9800"
                                          : "#D1D1D1",
                                    }}
                                  >
                                    {data.LastMonth_Application}
                                  </p>
                                </div>
                                <div className="col mt-2">
                                  <p style={{ fontSize: "15px" }}>
                                    Application
                                  </p>
                                </div>
                                <div className="col">
                                  <p
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "bold",
                                      color:
                                        data.Current_Application >
                                        data.LastMonth_Application
                                          ? "#FF9800"
                                          : "#D1D1D1",
                                    }}
                                  >
                                    {data.Current_Application}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {regionEnrolData.length > itemsPerPage && (
                    <button
                      className="btn btn-dark position-absolute end-0 top-50"
                      style={{ marginRight: "-50px" }}
                      onClick={nextItems}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Section 6 */}
          {isSec6Visible && (
            <div
              className={`col ${isSec6Visible ? slideVisible : slideExit}`}
              style={{ minHeight: "30rem" }}
            >
              <div
                className="container-fluid p-4"
                style={{ fontFamily: "Roboto-Regular", marginTop: "-22px" }}
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="card p-3 text-center dark text-white">
                      <h5>
                        Total SBU Sales ({currYear}){" "}
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            cursor: "pointer",
                            color: "white",
                            opacity: "90%",
                          }}
                          title="Total sales = Course Fees"
                        />
                      </h5>
                      <br />
                      <h3 style={{ color: "orange", fontWeight: "bold" }}>
                        $
                        {parseFloat(totalSales)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                      <p style={{ fontSize: "20px" }}>Year-to-Date</p>
                      <p style={{ fontSize: "13px", color: "orange" }}>
                        Total Students:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {totalStudents.count}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card p-3 text-center dark text-white">
                      <h5>
                        SBU with Highest YTD Sales{" "}
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            cursor: "pointer",
                            color: "white",
                            opacity: "90%",
                          }}
                          title="Total sales = Course Fees"
                        />{" "}
                        <br></br>
                        <span style={{ fontSize: "12px" }}>
                          (excl. Executive Education)
                        </span>
                      </h5>
                      <h3 style={{ color: "orange", fontWeight: "bold" }}>
                        $
                        {parseFloat(highestRevenue.amount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                      <p style={{ fontSize: "20px" }}>{highestRevenue.sbu}</p>
                      <p style={{ fontSize: "13px", color: "orange" }}>
                        Total Students:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {highStudents.count}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card p-3 text-center dark text-white">
                      <h5>
                        SBU with Lowest YTD Sales{" "}
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            cursor: "pointer",
                            color: "white",
                            opacity: "90%",
                          }}
                          title="Total sales = Course Fees"
                        />{" "}
                        <br></br>
                        <span style={{ fontSize: "12px" }}>
                          (excl. Executive Education)
                        </span>
                      </h5>
                      <h3 style={{ color: "orange", fontWeight: "bold" }}>
                        $
                        {parseFloat(lowestRevenue.amount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                      <p style={{ fontSize: "20px" }}>{lowestRevenue.sbu}</p>
                      <p style={{ fontSize: "13px", color: "orange" }}>
                        Total Students:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {lowStudents.count}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex flex-row justify-content-between mt-3 gap-4"
                  style={{ height: "50%", width: "100%" }}
                >
                  <Graph
                    data={averageTime}
                    title={
                      currYear +
                      " Application to Enrolment (Average Time Taken)"
                    }
                    title1={"DAYS"}
                    title2={"SBU"}
                    graphType={"barChart3"}
                    interval={2}
                    height="100%"
                    width="50%"
                  />
                  <Graph
                    data={saleBySBUCode}
                    title={currYear + " Year-to-Date Sales by SBU"}
                    graphType={"doughnut3"}
                    height="100%"
                    width="50%"
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card p-3 dark text-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-white m-0 fw-bold">
                          Month-to-Date Sales by SBU for{" "}
                          {dateFromNumToMonthLong(selectedSaleMonth)}
                        </h5>

                        <div className="d-flex align-items-center">
                          <span className="text-white me-2">Select Month:</span>
                          <select
                            className="form-select w-auto text-white border-0"
                            style={{ background: "#373B3E" }}
                            value={selectedSaleMonth}
                            onChange={handleSaleMonthChange}
                          >
                            {Array.from({ length: 12 }, (_, i) => i + 1).map(
                              (month) => (
                                <option key={month} value={month}>
                                  {dateFromNumToMonthLong(month)}
                                </option>
                              )
                            )}
                          </select>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            className="position-absolute"
                            style={{
                              right: "30px",
                              transform: "translateY(-20%)",
                              pointerEvents: "none",
                              color: "white",
                            }}
                          />
                        </div>
                      </div>

                      <table className="table table-dark table-fixed mt-3">
                        <thead
                          className="thead-dark"
                          style={{ fontSize: "medium" }}
                        >
                          <tr>
                            {saleSBU.length > 0 ? (
                              saleSBU.map((item, index) => (
                                <th
                                  key={index}
                                  style={{
                                    width: `${100 / saleSBU.length}%`,
                                    textAlign: "center",
                                  }}
                                >
                                  {item.sbu_description}
                                </th>
                              ))
                            ) : (
                              <th style={{ textAlign: "center" }}>
                                No Data Available
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody style={{ fontSize: "medium" }}>
                          <tr>
                            {saleSBU.length > 0 ? (
                              saleSBU.map((item, index) => (
                                <td
                                  key={index}
                                  style={{
                                    width: `${100 / saleSBU.length}%`,
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  $
                                  {item.Total.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              ))
                            ) : (
                              <td
                                colSpan={saleSBU.length || 1}
                                className="text-center"
                              >
                                No Sales Data
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Section 7 */}
          {isSec7Visible && (
            <div
              className={`col ${
                isSec7Visible ? slideVisible : slideExit
              } d-flex justify-content-center align-items-center`}
              style={{ minHeight: "42rem" }}
            >
              {/* <Map data1={studentCounts} data2={studentCountries}/> */}
              <div className={"container text-center h-100"}>
                <div className={"row row-cols-2 h-100"}>
                  <div className={"col"}>
                    <Graph
                      data={studentGenders}
                      title={"Enrolment By Gender Groups - " + currYear}
                      graphType={"pie"}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      title={
                        "Yearly Comparison of Enrolled Students and Applicants"
                      }
                      title2="No. of Student/Applicants"
                      data={EnrolmentToApplicantRatioYearly}
                      data1={EnrolmentToApplicantRatioPrevYear}
                      label={currYear}
                      label2={currYear - 1}
                      graphType={"barChart2"}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      data={studentAgeGroups}
                      title={"Enrolment By Age Groups - " + currYear}
                      title1="Student Count"
                      title2="Age Groups"
                      graphType={"barChart"}
                      interval={5}
                      height="95%"
                      width="100%"
                    />
                  </div>
                  <div className={"col"}>
                    <Graph
                      data={EnrolledStudentDemographicsByCountry}
                      title={"Enrolled Students by Country"}
                      graphType="pie"
                      height="95%"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Section 8 */}
          {isSec8Visible && (
            <div
              className={`col ${
                isSec8Visible ? slideVisible : slideExit
              } d-flex justify-content-center align-items-center`}
              style={{ minHeight: "42rem", fontFamily: "Roboto-Regular" }}
            >
              <div
                className={"d-flex flex-column"}
                style={{
                  padding: "15px 20px",
                  borderRadius: "8px",
                  background: "rgba(40, 44, 52, 0.8)",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "22px",
                    }}
                  >
                    Recruitment Team Members Away From Office
                  </span>

                  <div className="d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "inline-block",
                        }}
                      ></span>
                      <small style={{ color: "white" }}>Full Day</small>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "orange",
                          display: "inline-block",
                        }}
                      ></span>
                      <small style={{ color: "white" }}>Half Day</small>
                    </div>
                  </div>
                </div>

                <div className="custom-calendar-wrapper">
                  <Calendar
                    className="dark-calendar"
                    tileContent={({ date, view }) => {
                      if (view === "month") {
                        const matches = leaveData.filter(
                          (item) =>
                            new Date(item.date).toDateString() ===
                            date.toDateString()
                        );

                        return matches.length > 0 ? (
                          <div className="tile-note-wrapper">
                            {matches.map((item, index) => (
                              <div
                                className={`tile-note ${
                                  item.number_day === 0.5 ? "half-day" : "full-day"
                                }`}
                                key={index}
                              >
                                
                                <span className="tile-note-text">{item.name}</span>
                              </div>
                            ))}
                          </div>
                        ) : null;
                        
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className="col-1 d-flex align-items-center"
            style={{ width: "3%" }}
          >
            <button
              className="btn btn-secondary"
              style={{ transform: "rotate(-90deg)" }}
              onClick={changeSection}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faSortDown}
                style={{ width: "2rem" }}
              />
            </button>
          </div>

          {/* ======= GRAPH FOR ENROLL BY COUNTRY
            <Graph data={enrollCountry} title={"Student Enrolment by Country " + currYear} graphType={"doughnut"}/> 
            */}

          {/* ========== STUDENT APPLICATION 2023 vs 2024
            <div className="col" style={{height:'80vh !important'}}>
              <div style={{height:'50%'}}>
                <Graph data={applyVsCountry} title={"Student Application by Country " + currYear} graphType={"doughnut"}/>
              </div>
              <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                <Graph data={applyVsCountryPrev} title={"Student Application by Country " + prevYear} graphType={"doughnut"}/>
              </div>
            </div> */}
        </div>
      </Container>
    </div>
  );
}

export default DashboardHome;
