import "../../src/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserTie,
  faUserSecret,
  faCalendarAlt,
  faSortUp,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function InfoCard(props) {
  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);

  async function callbacks() {
    getTodayEnrolStudentData();
    getTodayApplicantStudentData();
  }
  const navigate = useNavigate();
  const [enrolmentData, setEnrolmentData] = useState({});

  async function getTodayEnrolStudentData() {
    const url = process.env.REACT_APP_API + "/getTodayEnrolStudent";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);

      if (response.data.success && Array.isArray(response.data.data)) {
        if (response.data.data.length > 0) {
          setEnrolmentData(response.data.data[0]);
        } else {
          setEnrolmentData({});
        }
      } else {
        console.error("Invalid API response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }

      if (error.response?.status === 401) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  }

  const [applicantData, setApplicantData] = useState({});

  async function getTodayApplicantStudentData() {
    const url = process.env.REACT_APP_API + "/getTodayApplicantStudent";
    const configs = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url, configs);

      if (response.data.success && Array.isArray(response.data.data)) {
        if (response.data.data.length > 0) {
            setApplicantData(response.data.data[0]);
        } else {
            setApplicantData({});
        }
      } else {
        console.error("Invalid API response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }

      if (error.response?.status === 401) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  }

  useEffect(() => {
    getTodayEnrolStudentData();
    getTodayApplicantStudentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Card
        className="dark"
        style={{ width: "90%", minHeight: "73vh", marginTop: "10px" }}
      >
        <Card.Body>
          <div className="d-flex flex-wrap" style={{ paddingTop: "10px" }}>
            <div
              className="col-3"
              style={{ margin: "auto", textAlign: "left" }}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faCalendarAlt}
                className="icons-height"
              />
            </div>
            <div className="col">
              <h1 className={"info-3 d-flex flex-row"}>
                <div style={{ color: "orange", paddingRight: "10px" }}>
                  {props.remainingDays}
                </div>
                <div className="info-5">Days</div>
              </h1>
              <h1 className={"info-4"}>To New Year {props.nextYear}</h1>
            </div>
          </div>
          <div className="divider"></div>
          <div className="d-flex flex-wrap">
            <div
              className="col-3"
              style={{ margin: "auto", textAlign: "left" }}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faUser}
                className="icons-height"
              />
            </div>

            <div className="col">
              <h1
                className="info-3 d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <span style={{ color: "orange", fontWeight: "bold" }}>
                  {props.stuEnrollCur}
                </span>

                <span className="info-5">
                  &#x28;{props.prevYear}: {props.stuEnrollPrev}&#x29;
                </span>

                <span
                  className="d-flex align-items-center"
                  style={{
                    color:
                      enrolmentData.today_count === 0 ? "#e74c3c" : "#2ecc71",
                    fontSize: "18px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={enrolmentData.today_count === 0 ? faMinus : faSortUp}
                    style={{
                      fontSize: "25px",
                      marginRight: "5px",
                      marginTop:
                        enrolmentData.today_count === 0 ? "3px" : "10px",
                      marginLeft: "15px",
                    }}
                  />
                  {enrolmentData.today_count === 0
                    ? ""
                    : `+${enrolmentData.today_count}`}
                </span>
              </h1>

              <h1 className="info-4">Student Enrolments</h1>
            </div>
          </div>
          <div className="divider"></div>
          <div className="d-flex flex-wrap">
            <div
              className="col-3"
              style={{ margin: "auto", textAlign: "left" }}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faUser}
                className="icons-height"
              />
            </div>

            <div className="col">
              <h1
                className="info-3 d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <span style={{ color: "orange", fontWeight: "bold" }}>
                {props.stuApplyCur}
                </span>

                <span className="info-5">
                  &#x28;{props.prevYear}: {props.stuApplyPrev}&#x29;
                </span>

                <span
                  className="d-flex align-items-center"
                  style={{
                    color:
                      applicantData.today_count === 0 ? "#e74c3c" : "#2ecc71",
                    fontSize: "18px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={applicantData.today_count === 0 ? faMinus : faSortUp}
                    style={{
                      fontSize: "25px",
                      marginRight: "5px",
                      marginTop:
                      applicantData.today_count === 0 ? "3px" : "10px",
                      marginLeft: "15px",
                    }}
                  />
                  {applicantData.today_count === 0
                    ? ""
                    : `+${applicantData.today_count}`}
                </span>
              </h1>

              <h1 className="info-4">Student Applications</h1>
            </div>
          </div>
          <div className="divider"></div>
          {/* <div className="d-flex flex-wrap" style={{display: 'flex', alignItems: 'center'}}> 
                        <div className="col-3" style={{margin:'auto', textAlign:'left'}}>
                            <FontAwesomeIcon color={"white"} icon={faUser} style={{height:'4rem'}}/>
                        </div>
                        <div className="col" style={{paddingLeft:'5px'}}>
                            <div className="row">
                                <h1 style={{paddingBottom:'1rem', color:'white', fontSize:'17px', paddingLeft:'5px'}}>Enrolled</h1>
                            </div>
                            <div className="row d-flex" style={{ paddingLeft:'5px'}} >
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'}  style={{fontSize:'20px'}}>{acadCount}</h1>
                                    <h1 className={"info-4 p-0"}>Acad</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{prepCount}</h1>
                                    <h1 className={"info-4 p-0"}>Prep</h1>
                                </div>  
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{intCount}</h1>
                                    <h1 className={"info-4 p-0"}>Int'l</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{aLvlCount}</h1>
                                    <h1 className={"info-4 p-0"}>A-Lvl</h1>
                                </div> 
                                <div className="col p-0">
                                    <h1 className={'info-4 p-0'} style={{fontSize:'20px'}}>{othersCount}</h1>
                                    <h1 className={"info-4 p-0"}>Others</h1>
                                </div>
                            </div>   
                        </div>
 
                    </div>
                    <div className="divider"></div> */}

          <div className="d-flex flex-wrap">
            <div
              className="col-3"
              style={{ margin: "auto", textAlign: "left" }}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faUserSecret}
                className="icons-height"
              />
            </div>
            <div className="col">
              <h1 className={"info-3"}>
                <div style={{ color: "orange", paddingRight: "10px" }}>
                  {props.agents}
                </div>
              </h1>
              <h1 className={"info-4"}>Activated Agents</h1>
            </div>
          </div>
          <div className="divider"></div>
          <div className="d-flex flex-wrap">
            <div
              className="col-3"
              style={{ margin: "auto", textAlign: "left" }}
            >
              <FontAwesomeIcon
                color={"white"}
                icon={faUserTie}
                className="icons-height"
              />
            </div>
            <div className="col">
              <h1 className={"info-3"}>
                <div style={{ color: "orange", paddingRight: "10px" }}>
                  {props.recruiters}
                </div>
              </h1>
              <h1 className={"info-4"}>Recruiters</h1>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
export default InfoCard;
